import { types } from "@redux/types/types";

const initialState = {
    exportData: [],
    registros: [],
    endPoint: null,
};

const list = (state = initialState, action) => {
    switch (action.type) {
        case types.load:
            // If server returns an error, you can handle it here
            if (!action.payload.data) action.payload.data = [];
            return {
                ...state,
                endPoint: action.payload.endPoint,
                registros: [...action.payload.data],
            };
        case types.delete:
            return {
                ...state,
                registros: state.registros.filter((reg) => reg.id !== action.payload),
            };
        case types.deleteRepair:
            state.registros[action.payload.index].repairs = state.registros[action.payload.index].repairs.filter(
                (reg) => reg.id !== action.payload.id,
            );

            return {
                ...state,
                registros: [...state.registros],
            };
        case types.cleaningAll:
            return {
                ...state,
                endPoint: null,
                registros: [],
            };
        case types.setExportData:
            return {
                ...state,
                exportData: [...action.payload.data],
            };
        case types.change:
            const { index, name, valor } = action.payload;

            const newReg = {
                ...state.registros[index],
                [name]: valor,
            };

            return {
                ...state,
                registros: state.registros.map((reg, i) => (i === index ? newReg : reg)),
            };

        case types.swap:
            const { id, ind, move } = action.payload;

            const newRegistros = [...state.registros];
            for (const e of newRegistros) {
                if (e.id === id) {
                    const mainReg = e.order[ind];
                    const above = e.order[ind - 1];
                    const below = e.order[ind + 1];

                    if (move === "up" && above !== undefined) {
                        e.order[ind] = above;
                        e.order[ind - 1] = mainReg;
                    }

                    if (move === "down" && below !== undefined) {
                        e.order[ind] = below;
                        e.order[ind + 1] = mainReg;
                    }
                }
            }

            return {
                ...state,
                registros: [...newRegistros],
            };
        case types.swapC:
            const { id: idC, index: mainIndC, indO, move: moveC, indOS } = action.payload;
            const newRegistrosC = [...state.registros];

            const mainRegC = state.registros.find((e) => e.id === idC).order[indO].orderSet[indOS];
            const aboveC = state.registros.find((e) => e.id === idC).order[indO].orderSet[indOS - 1];
            const belowC = state.registros.find((e) => e.id === idC).order[indO].orderSet[indOS + 1];

            if (moveC === "up" && aboveC !== undefined) {
                newRegistrosC.find((e) => e.id === idC).order[indO].orderSet[indOS] = aboveC;
                newRegistrosC.find((e) => e.id === idC).order[indO].orderSet[indOS - 1] = mainRegC;
            }

            if (moveC === "down" && belowC !== undefined) {
                newRegistrosC.find((e) => e.id === idC).order[indO].orderSet[indOS] = belowC;
                newRegistrosC.find((e) => e.id === idC).order[indO].orderSet[indOS + 1] = mainRegC;
            }

            return {
                ...state,
                registros: [...newRegistrosC],
            };

        default:
            return state;
    }
};

export default list;
