export const types = {
    load: "[Load] Loading registers from table",
    setExportData: "[Set] Set Export Data",
    delete: "[Delete] Delete register",
    deleteRepair: "[Delete] Delete repair register",
    cleaningAll: "[Cleaning] Cleaning register from redux",
    change: "[Change] Changing value",
    swap: "[Swap] Swaping values",
    swapC: "[Swap] Swaping children values",
};
