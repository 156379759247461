// ** Initial State
const initialState = {};

const reserved = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reserved;
