import { formTypes } from "../../types/formReducer/types";

// ** Initial State
const initialState = {};

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case formTypes.setFormData:
            return {
                ...action.payload,
            };
        case formTypes.inputChange:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };

        case formTypes.initForm:
            return {
                ...action.payload,
            };

        case formTypes.initNestedForm:
            return {
                ...state,
                ...action.payload,
            };

        case formTypes.addRepeaterRegister:
            return {
                ...state,
                [action.payload.key]: [...state[action.payload.key], { ...action.payload.structure }],
            };

        case formTypes.removeRepeaterRegister:
            const newState = state[action.payload.key].filter((element, index) => index !== action.payload.position);
            return {
                ...state,
                [action.payload.key]: [...newState],
            };

        case formTypes.editRepeaterRegister:
            const { key, position, extraKey, extraPosition, obj } = action.payload;
            if (extraKey === "" && extraPosition === "") {
                state[key][position] = {
                    ...state[key][position],
                    [obj.name]: obj.value,
                };
            } else {
                state[key][extraPosition][extraKey][position] = {
                    ...state[key][extraPosition][extraKey][position],
                    [obj.name]: obj.value,
                };
            }
            return {
                ...state,
                [key]: [...state[key]],
            };

        case formTypes.cleanForm:
            return initialState;

        case formTypes.fillFormData:
            return {
                ...state,
                ...action.payload,
            };

        case formTypes.SwitchPermission:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    [action.payload.permiso]: action.payload.value,
                },
            };
        case formTypes.SwitchPermissionAll:
            const newPermision = Object.entries(state[action.payload.key]).map((el) => {
                return { id: [el[0]], value: action.payload.value };
            });

            const newP = newPermision.reduce((obj, item) => Object.assign(obj, { [item.id]: item.value }), {});

            return {
                ...state,
                [action.payload.key]: {
                    ...newP,
                },
            };
        case formTypes.FillSelect:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    select: action.payload.value,
                },
            };

        default:
            return state;
    }
};

export default formReducer;
