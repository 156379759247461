// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "@auth/redux/reducer";
import navbar from "./navbar";
import layout from "./layout";

import list from "./custom";
import formValidator from "./formValidator";

import formReducer from "./formReducer";
import select from "./selects";

import fileUpload from "./fileUpload";

import calenderReducer from "./calendar";
import alertReducer from "./alert";
import reserved from "./reserved";

const rootReducer = combineReducers({
    auth,
    navbar,
    layout,
    list,
    formReducer,
    select,
    fileUpload,
    calenderReducer,
    formValidator,
    alertReducer,
    reserved,
});

export default rootReducer;
